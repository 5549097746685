// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commercial-artists-js": () => import("./../../../src/pages/commercial-artists.js" /* webpackChunkName: "component---src-pages-commercial-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fiction-artists-js": () => import("./../../../src/pages/fiction-artists.js" /* webpackChunkName: "component---src-pages-fiction-artists-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-artists-js": () => import("./../../../src/pages/new-artists.js" /* webpackChunkName: "component---src-pages-new-artists-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-artist-commercial-js": () => import("./../../../src/templates/artist/commercial.js" /* webpackChunkName: "component---src-templates-artist-commercial-js" */),
  "component---src-templates-artist-fiction-js": () => import("./../../../src/templates/artist/fiction.js" /* webpackChunkName: "component---src-templates-artist-fiction-js" */),
  "component---src-templates-artist-new-js": () => import("./../../../src/templates/artist/new.js" /* webpackChunkName: "component---src-templates-artist-new-js" */)
}

